<template>
  <div>
    <CRow>
      <CCol md="12">
        <ProfileDataData />
      </CCol>
    </CRow>
    <CRow>
      <!-- <CCol md="6">
        <ProfileDataImage />
      </CCol> -->
      <CCol md="6">
        <ProfileDataChangePassword />
      </CCol>
    </CRow>
  </div>
</template>

<script>
// import ProfileDataImage from "./ProfileDataImage";
import ProfileDataData from "./ProfileDataData";
import ProfileDataChangePassword from "./ProfileDataChangePassword";
export default {
  name: "ProfileData",
  components: {
    // ProfileDataImage,
    ProfileDataData,
    ProfileDataChangePassword,
  },
};
</script>
